import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UX2, constants as coreConstants } from '@wsb/guac-widget-core';
import {
  dataAids,
  getGalleryDataAid,
  getGalleryDataRouteProps
} from '../../common/constants/dataAids';
import wrapWithDeviceDetection from '../../common/wrapWithDeviceDetection';
import RenderLightbox from '../../common/renderLightbox';
import GalleryImage from '../../common/components/GalleryImage';

const IMGS_PER_PAGE = 6;
const styles = {
  container: {
    overflow: 'hidden',
    position: 'relative',
    cursor: 'pointer',
    marginBottom: 'small',
    [':hover']: {
      '.dim': {
        opacity: 1,
        transition: 'all .3s'
      }
    }
  },
  overlay: {
    opacity: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    height: '100%',
    width: '100%',
    color: 'highContrast',
    backgroundColor: 'sectionOverlay',
    zIndex: '1',
    textAlign: 'center'
  },
  fixedHeightImageStyles: {
    maxHeight: '660px',
    objectFit: 'cover',
    width: '100%'
  },
  moreLinkContainer: {
    paddingVertical: 'xlarge',
    textAlign: 'center'
  }
};

export default wrapWithDeviceDetection(
  class Gallery6 extends Component {
    constructor() {
      super(...arguments);
      this.state = {
        showLightbox: false,
        selectedIndex: 0,
        page: 1
      };
      this.hideLightbox = this.hideLightbox.bind(this);
      this.handleClick = this.handleClick.bind(this);
      this.loadMore = this.loadMore.bind(this);
    }

    static get propTypes() {
      return {
        id: PropTypes.string,
        category: PropTypes.string,
        device: PropTypes.string,
        galleryImages: PropTypes.array.isRequired,
        renderAsThumbnail: PropTypes.bool,
        renderMode: PropTypes.string,
        section: PropTypes.string,
        size: PropTypes.string,
        staticContent: PropTypes.object
      };
    }

    hideLightbox() {
      this.setState({
        showLightbox: false
      });
    }

    handleClick(index) {
      this.setState({
        showLightbox: true,
        selectedIndex: index
      });
    }

    loadMore(e) {
      e.stopPropagation();
      this.setState({ page: this.state.page + 1 });
    }

    renderSeeMoreText() {
      const { staticContent } = this.props;

      return (
        <UX2.Element.Block style={ styles.moreLinkContainer }>
          <UX2.Element.MoreLink.Expand
            role='button'
            tabIndex='0'
            onClick={ this.loadMore }
            data-aid={ dataAids.GALLERY_MORE_BUTTON }
            children={ staticContent.showMore }
            data-edit-interactive={ true }
          />
        </UX2.Element.Block>
      );
    }

    renderPages(shouldRenderShorterPreview) {
      const { galleryImages } = this.props;
      const { page } = this.state;

      const IMGS_TO_RENDER = shouldRenderShorterPreview ? 3 : page * IMGS_PER_PAGE;

      return (
        <UX2.Component.Grid inset={ false }>
          <UX2.Component.Grid.Cell>
            { galleryImages
              .slice(0, IMGS_TO_RENDER)
              .map(({ image, caption, externalLink }, index) => {
                const imageProps = {
                  'imageData': image,
                  'style': styles.fixedHeightImageStyles,
                  'data-aid': getGalleryDataAid(index),
                  ...getGalleryDataRouteProps(index, { isImage: true })
                };

                return (
                  <UX2.Element.Block
                    key={ index }
                    style={ styles.container }
                    onClick={
                      !externalLink
                        ? () => {
                          this.handleClick(index);
                        }
                        : null
                    }
                  >
                    { caption && (
                      <UX2.Element.Heading.Major
                        group='Group'
                        style={ styles.overlay }
                        children={ caption }
                        className='dim'
                        data-aid={ dataAids.GALLERY_CAPTION_RENDERED }
                        { ...getGalleryDataRouteProps(index, { isImage: false }) }
                        richtext
                      />
                    ) }
                    <GalleryImage
                      imageProps={ imageProps }
                      externalLink={ externalLink }
                      background={ false }
                    />
                  </UX2.Element.Block>
                );
              }) }
          </UX2.Component.Grid.Cell>
        </UX2.Component.Grid>
      );
    }

    render() {
      const { galleryImages, renderMode } = this.props;
      const { page } = this.state;
      const pages = Math.ceil(galleryImages.length / IMGS_PER_PAGE);
      const isLayoutView = renderMode === coreConstants.renderModes.DISPLAY;
      const isAddSectionView = renderMode === coreConstants.renderModes.ADD;
      const shouldRenderShorterPreview = isLayoutView || isAddSectionView;

      return (
        <React.Fragment>
          { galleryImages.length ? this.renderPages(shouldRenderShorterPreview) : null }
          { pages > page && !shouldRenderShorterPreview ? this.renderSeeMoreText() : null }
          { RenderLightbox.call(this) }
        </React.Fragment>
      );
    }
  }
);
